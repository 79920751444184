// import CookieConsent from "react-cookie-consent"
import { lazy, Suspense} from "react"
import Loader from "react-loader-spinner";
import { BrowserRouter, Route, Switch,useLocation } from "react-router-dom"
import { lowerCaseAllParamKeys } from "./constants";

// import About from "./pages/About/About"
// import Contact from "./pages/Contact/Contact"
// import DealPage from "./pages/Deal/Deal"
// import DSMI from "./pages/DSMI/DSMI"
// import HealthCheck from "./pages/HealthCheck/HealthCheck"
// import LandingPage from "./pages/Landing/Landing"
// import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy"
// import ThankyouPage from "./pages/Thankyou/Thankyou"
// import Progressive from "./pages/Progressive/Progressive"
// import Listing from "./pages/Listing/Listing"


const About = lazy(() => import("./pages/About/About"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const DealPage = lazy(() => import("./pages/Deal/Deal"));
const KiaDealsPage = lazy(() => import("./pages/Deal/KiaDealsPage/KiaDealsPage"));
const DealPageT3 = lazy(() => import("./pages/Deal/t3/Deal"));
const SpecPage = lazy(() => import("./pages/Spec/Spec"));
const DSMI = lazy(() => import("./pages/DSMI/DSMI"));
const HealthCheck = lazy(() => import("./pages/HealthCheck/HealthCheck"));
const LandingPage = lazy(() => import("./pages/Landing/Landing"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const ThankyouPage = lazy(() => import("./pages/Thankyou/Thankyou"));
const Thanks = lazy(() => import("./pages/Thanks/Thanks"));
const Progressive = lazy(() => import("./pages/Progressive/Progressive"));
const ProgressiveForm = lazy(() => import("./pages/ProgressiveForm/ProgressiveForm"));
const Listing = lazy(() => import("./pages/Listing/Listing"));
const UsedCarListing = lazy(() => import("./pages/UsedCarListing/UsedCarListing"));
const FindCarDeals  = lazy(() => import("./pages/FindCarDeals/FindCarDeals"));
const MakeLander  = lazy(() => import("./pages/MakeLander/MakeLander"));
const ModelPage  = lazy(() => import("./pages/ModelPage/ModelPage"));
// const DealPageT5  = lazy(() => import("./pages/Deal/t5/Deal"))
const VINPage = lazy(() => import("./pages/Vin/Vin"));

function AppRouter(props){
    const location = useLocation();

    // Extract the "paramName" query parameter from the location object
    const lowerCaseParams = lowerCaseAllParamKeys();
    // console.log("test",lowerCaseParams['utm_source'])
    const paramName = new URLSearchParams(location.search).get('tmpl');
    const make = new URLSearchParams(location.search).get('make');
    const segment = new URLSearchParams(location.search).get('segment');
    const params = new URLSearchParams(window.location.search);

    // Determine which Route component to render based on the value of paramName
    let routeComponent;

    if (paramName === 't3') {
        routeComponent = (
            <Route path={["/deals", "/deals_ae"]} render={() =>
                (<DealPage templateId={props.templateId}></DealPage>)
            }></Route>
        );
    } else if (lowerCaseParams['utm_source'] && lowerCaseParams['utm_source']?.toLowerCase() === 'buyerlink') {
        routeComponent = (
            <Route path={["/deals", "/deals_ae"]} render={() =>
                (<KiaDealsPage templateId={props.templateId}></KiaDealsPage>)
            }></Route>
        );
    } else {
        routeComponent = (
            // Define the default Route component here
            <Route path={["/deals", "/deals_ae"]} render={()=>
                (<DealPage templateId={props.templateId}></DealPage>)
            }></Route>
        );
    }

    return (
        <>
            <BrowserRouter>
                <Suspense fallback={paramName!=='t3'?<div className="h-full-vh flex justify-center items-center"><Loader type="ThreeDots" color="#e3e3e3" height={50} width={70}/></div>:<div className="h-full-vh flex justify-center items-center" style={{flexDirection:"column"}}><Loader type="ThreeDots" color="#eb5e00" height={50} width={70}/><p style={{color:"#eb5e00"}}>Searching for dealers....</p></div>}>
                    <Switch>
                        <Route path="/dsmi" component={DSMI}>
                        </Route>
                        <Route path="/contact" component={Contact}>
                        </Route>
                        <Route path="/about" component={About}>
                        </Route>
                        <Route path="/privacy-policy" component={PrivacyPolicy}>
                        </Route>
                        <Route path="/healthcheck" component={HealthCheck}>
                        </Route>
                        <Route path="/model/:modelName" component={ModelPage}>
                        </Route>
                        <Route path="/how-to-decode-a-vin" component={VINPage}>
                        </Route>
                        
                        <Route path="/progressive" render={()=>
                            (<Progressive templateId={props.templateId}></Progressive>)
                        }></Route>

                        <Route path="/spec" render={()=>
                            (<SpecPage templateId={props.templateId}></SpecPage>)
                        }></Route>



                        <Route path="/progressiveform" render={()=>
                            (<ProgressiveForm templateId={props.templateId}></ProgressiveForm>)
                        }></Route>

                        {/*<Route path={["/deals", "/deals_ae"]} render={()=>*/}
                        {/*    (<DealPage templateId={props.templateId}></DealPage>)*/}
                        {/*}></Route>*/}

                        {routeComponent}

                        <Route path="/listing" render={()=>
                            (<Listing templateId={props.templateId}></Listing>)
                        }></Route>

                        <Route exact path="/marketplace" render={()=>
                            (<UsedCarListing templateId={props.templateId}></UsedCarListing>)
                        }></Route>

                        <Route path="/thankyou" render={()=>
                            (<ThankyouPage templateId={props.templateId}></ThankyouPage>)
                        }></Route>

                        { (make || segment) &&
                            <Route path="/thank-you" render={()=>
                                (<Thanks templateId={props.templateId}></Thanks>)
                            }></Route>
                        }
                                               
                        <Route path="/find-cars" render={()=>
                            (<FindCarDeals templateId={props.templateId}></FindCarDeals>)
                        }></Route>

                        <Route path="/make/:makeName" render={()=>
                            (<MakeLander templateId={props.templateId} ></MakeLander>)
                        }></Route>
                        
                        <Route path="/" render={()=>
                            (<LandingPage templateId={props.templateId}></LandingPage>)
                        }></Route>

                        
                    </Switch>
                </Suspense>
                
            </BrowserRouter>
            {/* <CookieConsent
                enableDeclineButton
                flipButtons
                location="bottom"
                buttonText="Accept"
                declineButtonText="Reject"
                style={{ background: "rgba(0,0,0,0.60)", alignItems: 'center' }}
                buttonStyle={{ color: "#526FFF", fontSize: "13px", background: "#fff", borderRadius: '8px', padding: '10px 20px', margin: '15px' }}
                declineButtonStyle = {{ background: 'none', border: "1px solid white", borderRadius: '8px',padding: '9px 20px', fontSize: "13px", boxSizing: 'border-box', margin: '15px 15px 15px 0'}}
                contentStyle={{fontSize: '12px', flex: '1 0 100px'}}
                onAccept={() => {
                    if(!getCookie('uuid')){
                        setCookie('uuid',uuidv4())
                    }
                }}
                onDecline={()=>{

                }}
            >
                This website uses cookies to enhance the user experience.
            </CookieConsent> */}
        </>
    )
}

export default AppRouter;